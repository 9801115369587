.card {
  background-color: lightgrey;
  border-radius: 10px;
  width: 300px;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__img {
  width: 50px;
  /* margin-right: 20px; */
}

.card__img img {
  width: 100%;
}
