ul,
li {
  list-style-type: none;
}

.draggable-list__item {
  /* padding: 10px 0; */
  border-top: 2px solid transparent;
}

.draggable-list__item * {
  pointer-events: none;
}

.draggable-list__item.dragstart {
  opacity: 0.5;
}

.draggable-list__item.dragover {
  border-top-color: green;
}
